import React from "react";
import { graphql, useStaticQuery, Link } from "gatsby";

import TextBlock from "../components/blocks/text-block.js";

import "../css/large-text-grid.css";

const LargeTextGrid = ({ content, section, textBlock }) => {
  const data = useStaticQuery(graphql`
    query {
      allWpSolution(
        filter: { parentId: { eq: null } }
        sort: { fields: title, order: ASC }
      ) {
        nodes {
          id
          title
          link
          cptSolution {
            shortDescription
          }
        }
      }
      allWpWhoWeServe( sort: { fields: title, order: ASC }) {
        nodes {
          id
          title
          link
          cptWhoWeServe {
            imageLeftExcerpt
          }
        }
      }
    }
  `);

  const allSolutions = data.allWpSolution.nodes;
  const allServe = data.allWpWhoWeServe.nodes;

  // Determine content type and dynamically retrieve fields
  let nodes = [];
  let getTitle = (item) => "";
  let getDescription = (item) => "";
  
  if (content.component === "solutions") {
    nodes = allSolutions;
    getTitle = (item) => item.title;
    getDescription = (item) => {
      // Return the description as is, no HTML escaping
      return item.cptSolution.shortDescription || '';
    };
  } else {
    nodes = allServe;
    getTitle = (item) => item.title;
    getDescription = (item) => {
      // Get raw description without escaping HTML
      const rawDescription = item.cptWhoWeServe.imageLeftExcerpt || ''; // Fallback to an empty string
      const maxLength = 125;
  
      // Split the text into words
      const words = rawDescription.split(' ');
      let truncatedDescription = '';
  
      // Add words until we reach the maxLength
      for (let word of words) {
        if ((truncatedDescription + word).length <= maxLength) {
          truncatedDescription += (truncatedDescription ? ' ' : '') + word;
        } else {
          break;
        }
      }
  
      // Add ellipsis if text was truncated
      return truncatedDescription.length < rawDescription.length ? truncatedDescription + '...' : truncatedDescription;
    };
  }

  return (
    <div
      className="full-width"
      style={{ backgroundColor: content.backgroundColor }}
    >
      <div
        className="container lgTextGridContainer"
        style={{ backgroundColor: content.backgroundColor }}
      >
        <div className="textBlock">
          <TextBlock item={textBlock} section={section} />
        </div>

        <div className="new-grid">
          {nodes &&
            nodes.map((node) => (
              <Link to={node.link} key={node.id}>
                <div
                  className="iconColumn"
                  style={{
                    backgroundColor: content.boxColor,
                    borderColor: content.hoverColor,
                    '--hover-bg-color2': content.hoverColor,
                  }}
                >
                  <div className="front-side">
                    <div className="iconTitle">{getTitle(node)}</div>
                    <p className="iconDescription" dangerouslySetInnerHTML={{ __html: getDescription(node) }}></p>
                    <p className="iconDescription iconCta">Learn More</p>
                  </div>
                </div>
              </Link>
            ))}
        </div>
      </div>
    </div>
  );
};

export default LargeTextGrid;
