import React from "react"
import { Link } from "gatsby";


import '../css/small-text-grid.css';

const SmallTextGrid = ({content, section}) => {
  const solutions = content?.components || [];
  
  return (
    <div className="full-width" style={{ backgroundColor: content.backgroundColor}}>
    <div className="container small-grid-container" style={{ backgroundColor: content.backgroundColor}}>
    {solutions.map((solution, index) => (
          <Link to={solution.link} key={index}>
          <div className={`solution-box ${section}`} style={{ backgroundColor: content.boxColor}}>
            <h3 className="solution-title">{solution.title}</h3>
           <div className="solution-link">Learn More</div>
          </div>
          </Link>
        ))}
    </div>
    </div>
  );
};

export default SmallTextGrid