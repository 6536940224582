import styled from 'styled-components';
import { graphql, navigate } from "gatsby";
import PropTypes from "prop-types";
import React, { Component } from "react";
import GravityFormForm from 'gatsby-plugin-gravity-forms-ignitro';

import '../css/contact-form.css';
import '../css/landing-page.css';

import Layout from "../layouts";
import Head from "../components/head";
import ImageHero from "../components/image-hero-v2";
import SectionHero from "../components/section-hero";

import ThreeColumn from "../components/three-column-v2";
import TwoColumn from "../components/two-column-v2";
import OneColumn from "../components/one-column";

import Articles from '../components/articles';
import QuoteSlider from '../components/quote-slider';
import SolutionsGrid from '../components/solution-grid';
import SmallTextGrid from '../components/small-text-grid';
import LargeTextGrid from '../components/large-text-grid';

import YoastSeoParts from '../fragments/seo';

const Default = ({ pageData }) => {
  const {
    seo,
    pageOptions,
    pageTitle,
    theContent, // Corrected from 'content'
    articles,
    phoneLink,
    allWpGfForm, // Corrected from 'gfForms'
    imageHero,
    section,
    noSignUp,
  } = pageData;

  let gfForm = ""; // Declaring gfForm here so it's accessible for forms
  return (
    <Layout noSignUp={noSignUp}>
      <Head title={seo.title} description={seo.metaDesc} meta={seo} />
      {/* Conditional rendering based on imageHero */}
      {
        imageHero.backgroundImage ? (
          <ImageHero
            section={section}
            bgimage={imageHero.backgroundImage}
            image={imageHero.frontImage}
            title={imageHero.headline}
            buttons={imageHero.buttons}
          />
        ) : (
          <SectionHero section={section} title={imageHero.headline || pageTitle}/>
        )
      }
      

      <div>
        <div className="container landing-page">
          {theContent?.length > 0 ? (
            theContent.map((item, index) => {
              switch (item.__typename) {
                case 'WpDefaultTemplate_Templatedefault_Content_ThreeColumn':
                  return (
                    <div key={index} className={`item-${index}`}>
                      <ThreeColumn content={item} section={section} />
                    </div>
                  );
                case 'WpDefaultTemplate_Templatedefault_Content_OneColumn':
                  gfForm = ""; // Reset gfForm
                  if (item.oneColumn && item.oneColumn[0].__typename === 'WpDefaultTemplate_Templatedefault_Content_OneColumn_OneColumn_Form') {
                    allWpGfForm.nodes.forEach(node => {
                      if (node.databaseId == item.oneColumn[0].formFormId) {
                        gfForm = node;
                      }
                    });
                  }    
                  return (
                    <div key={index} className={`one-col-container flex-container item-${index}`}>
                      <OneColumn content={item} section={section} phone={phoneLink} form={gfForm} />
                    </div>
                  );

                case 'WpDefaultTemplate_Templatedefault_Content_TwoColumn':
                  gfForm = ""; // Reset gfForm 
                  // Similar checks for left and right columns
                  if (item.leftColumn && item.leftColumn[0].__typename === 'WpDefaultTemplate_Templatedefault_Content_TwoColumn_LeftColumn_Form') {
                    allWpGfForm.nodes.forEach(node => {
                      if (node.databaseId == item.leftColumn[0].formFormId) {
                        gfForm = node;
                      }
                    });
                  }    
                  if (item.rightColumn && item.rightColumn[0].__typename === 'WpDefaultTemplate_Templatedefault_Content_TwoColumn_RightColumn_Form'){
                    allWpGfForm.nodes.forEach(node => {
                      if (node.databaseId == item.rightColumn[0].formFormId) {
                        gfForm = node;
                      }
                    });
                  }
                  return (
                    <div key={index} className={`two-col-container flex-container item-${index}`}>
                      <TwoColumn content={item} section={section} phone={phoneLink} form={gfForm}/>
                    </div>
                  );
                case 'WpDefaultTemplate_Templatedefault_Content_QuoteSlider':
                  return (
                    <div key={index} className={`item-${index}`}>
                      <QuoteSlider testimonials={item.largeTestimonialSlider} section={section} />
                    </div>
                  );
                case 'WpDefaultTemplate_Templatedefault_Content_Articles':
                  let articleLink = item.articles?.wpURI || '';
                  return (
                    <div key={index} className={`item-${index}`}>
                      <Articles
                        articlesCategoryId={item.articles.id}
                        title={item.articleTitle}
                        posts={articles}
                        link={articleLink}
                      />
                    </div>
                  );
                case 'WpDefaultTemplate_Templatedefault_Content_SolutionsGrid':
                  const textBlock = {
                    textBlockContent: item.textBlockContent,
                    textBlockHaveSuper: item.textBlockHaveSuper,
                    textBlockHeadline: item.textBlockHeadline,
                    textBlockSuper: item.textBlockSuper,
                    textBlockCtaButtons: item.textBlockCtaButtons,
                  };
                  return (
                    <div key={index} className={`item-${index}`}>
                     <SolutionsGrid content={item} section={section} textBlock={textBlock} />
                    </div>
                  );  
                case 'WpDefaultTemplate_Templatedefault_Content_SmallTextGrid':
                  return (
                    <div key={index} className={`item-${index}`}>
                      <SmallTextGrid content={item} section={section} />
                    </div>
                  ); 
                case 'WpDefaultTemplate_Templatedefault_Content_LargeTextGrid':
                  const textBlock2 = {
                    textBlockContent: item.textBlockContent,
                    textBlockHaveSuper: item.textBlockHaveSuper,
                    textBlockHeadline: item.textBlockHeadline,
                    textBlockSuper: item.textBlockSuper,
                    textBlockCtaButtons: item.textBlockCtaButtons,
                  };
                  return (
                    <div key={index} className={`item-${index}`}>
                      <LargeTextGrid content={item} section={section} textBlock={textBlock2} />
                    </div>
                  );          
                default:
                  return null;
              }
            })
          ) : (
            null
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Default;